export default class Url {
    get href() {
        return location.href;
    }

    static getParam(pKey: string) {
        const queryParams = new URLSearchParams(window.location.search);
        const paramKeyNormalized = pKey?.toLowerCase();
        const key = [...queryParams.keys()].find(k => k.toLowerCase() === paramKeyNormalized) as string;
        return queryParams.get(key);
    }

    static setParam(pKey: string, pValue: any) {
        const url = new URL(location.href);
        const params = new URLSearchParams(url.search);
        if (pValue) {
            params.set(pKey, pValue);
        } else {
            params.delete(pKey);
        }
        // let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + params.toString();
        url.search = params.toString();
        //window.history.pushState({path: newurl}, '', newurl);
        window.history.pushState({}, '', url);
    }

    static decodeURL(pUrl: string) {
        return decodeURIComponent(pUrl).replace(/%20/g, " ").replace(/%25/g, "%");
    }

    static decodObject(pValue: any) {
        pValue = JSON.stringify(pValue);
        pValue = encodeURIComponent(btoa(pValue));
        return pValue;
    }

    static setDecodedObject(pKey: string, pValue: any) {
        pValue = JSON.stringify(pValue);
        pValue = encodeURIComponent(btoa(pValue));

        this.setParam(pKey, pValue);
    }

    static getDecodedObject(pKey: string) {
        let value = this.getParam(pKey);
        value = atob(decodeURIComponent(value))
        try {
            return JSON.parse(value);
        } catch {
            alert("Failed to parse url params");
        }
        return null;
    }

    // Below are functions from o365.modules.utils.url.ts

    static hasQueryParameter(key: string) {
        const location = window.location;
        const searchString = location.search;
        const urlSearchParameters = new URLSearchParams(searchString);

        return urlSearchParameters.has(key);
    }

    static getQueryParameter(key: string): string | null {
        const location = window.location;
        const searchString = location.search;
        const urlSearchParameters = new URLSearchParams(searchString);

        return urlSearchParameters.get(key);
    }

    static setQueryParameter(key: string, value: string, reload: boolean = false): void {
        const location = window.location;
        const searchString = location.search;
        const urlSearchParameters = new URLSearchParams(searchString);

        urlSearchParameters.set(key, value);

        if (reload) {
            location.search = urlSearchParameters.toString();
        } else {
            const history = window.history;

            let newUrl = new URL(location.href);

            newUrl.search = urlSearchParameters.toString();

            history.replaceState(history.state, '', newUrl);
        }
    }

    static deleteQueryParameter(key: string, reload: boolean = false): void {
        const location = window.location;
        const searchString = location.search;
        const urlSearchParameters = new URLSearchParams(searchString);

        urlSearchParameters.delete(key);

        if (reload) {
            location.search = urlSearchParameters.toString();
        } else {
            const history = window.history;

            let newUrl = new URL(location.href);

            newUrl.search = urlSearchParameters.toString();

            history.replaceState(history.state, '', newUrl);
        }
    }

}